APP.accordion = function (element) {
	const el = $(element)
	const accordion = el.find('.accordion__item')
	const accordionHead = accordion.find('.accordion__head')

	const init = function () {
		accordionHead.on('click', handleToggle)
		if ($('.tour-package-preview').length) {
			$('.tour-package-preview').delegate('.accordion__head', 'click', handleToggle)
		}
	}

	const handleToggle = function (evt) {
		evt.preventDefault()
		const _this = $(this)
		const accordionShow = _this.parent()
		const accordionShowBody = accordionShow.find('.accordion__body')
		const accordionShowContent = accordionShow.find('.accordion__content')
		let accordionShowContentHeight = 0

		if (accordionShow.hasClass(CLASS._show)) {
			accordionShowBody.removeClass(CLASS._show)
			accordionShow.removeClass(CLASS._show)

			gsap.to(accordionShowBody, {
				duration: 0.6,
				height: 0,
				ease: 'power1.out',
				onComplete: function () {
					accordionShowBody.removeAttr('style')
					gsap.set(accordionShowBody, {
						display: 'none',
					})
				},
			})
		} else {
			accordionShowBody.addClass(CLASS._show)
			accordionShow.addClass(CLASS._show)

			gsap.set(accordionShowBody, {
				display: 'block',
				height: 0,
			})

			accordionShowContentHeight = accordionShowContent.outerHeight(true)

			gsap.to(accordionShowBody, {
				duration: 0.6,
				height: accordionShowContentHeight,
				ease: 'power1.out',
				onComplete: function () {
					accordionShowBody.removeAttr('style')
					gsap.set(accordionShowBody, {
						display: 'block',
					})
				},
			})
		}
	}

	init()
}
