APP.filterDetail = function () {
	const el = $('.filter-detail')
	const filterDetailItem = el.find('.filter-detail__item')

	const filterDetailItemCheckin = el.find('.filter-detail__item--checkin')
	const filterDetailItemCheckinText = filterDetailItemCheckin.find('.filter-detail__text')
	const filterDetailItemCheckinInput = filterDetailItemCheckin.find('#date-checkin')

	const filterDetailItemCheckout = el.find('.filter-detail__item--checkout')
	const filterDetailItemCheckoutText = filterDetailItemCheckout.find('.filter-detail__text')
	const filterDetailItemCheckoutInput = filterDetailItemCheckout.find('#date-checkout')

	const filterDetailDateCheckin = el.find('.filter-detail__date--checkin')
	const filterDetailDateCheckout = el.find('.filter-detail__date--checkout')

	const filterDetailItemWho = el.find('.filter-detail__item--who')
	const filterDetailItemWhoInput = filterDetailItemWho.find('#who')
	const filterDetailItemWhoText = filterDetailItemWho.find('.filter-detail__text')
	const filterDetailItemWhoTextDefault = filterDetailItemWhoText.data('text')
	const boxWho = filterDetailItemWho.find('.box-qty')
	const boxWhoItem = boxWho.find('.box-qty__item')

	const filterDetailItemRoom = el.find('.filter-detail__item--room')
	const filterDetailItemRoomInput = filterDetailItemRoom.find('#room')
	const filterDetailItemRoomText = filterDetailItemRoom.find('.filter-detail__text')
	const filterDetailItemRoomTextDefault = filterDetailItemRoomText.data('text')
	const boxRoom = filterDetailItemRoom.find('.box-qty')
	const boxRoomItem = boxRoom.find('.box-qty__item')

	const arrWhoText = []
	const arrWho = []
	const arrRoomText = []
	const arrRoom = []

	function init() {
		if (el.length === 0) return

		filterDetailItemCheckin.on('click', function () {
			const _this = $(this)

			filterDetailDateCheckin.addClass('filter-detail__date--show')
			filterDetailDateCheckout.removeClass('filter-detail__date--show')

			el.addClass('filter-detail--show')
			filterDetailItem.removeClass('filter-detail__item--bgwhite')
			_this.addClass('filter-detail__item--bgwhite')
		})

		filterDetailItemCheckout.on('click', function () {
			const _this = $(this)

			filterDetailDateCheckout.addClass('filter-detail__date--show')
			filterDetailDateCheckin.removeClass('filter-detail__date--show')

			el.addClass('filter-detail--show')
			filterDetailItem.removeClass('filter-detail__item--bgwhite')
			_this.addClass('filter-detail__item--bgwhite')
		})

		filterDetailItemWho.on('click', function () {
			const _this = $(this)
			el.addClass('filter-detail--show')
			filterDetailItem.removeClass('filter-detail__item--bgwhite')
			_this.addClass('filter-detail__item--bgwhite')

			boxWho.addClass('box-qty--show')
		})

		filterDetailItemRoom.on('click', function () {
			const _this = $(this)
			el.addClass('filter-detail--show')
			filterDetailItem.removeClass('filter-detail__item--bgwhite')
			_this.addClass('filter-detail__item--bgwhite')

			boxRoom.addClass('box-qty--show')
		})

		boxWhoItem.each(function () {
			const _this = $(this)
			const input = _this.find('input')
			const inputText = input.data('text')
			arrWhoText.push(inputText)
		})

		APP.qtyVido('.qty-vido--adults', {
			qtyMax: 10,
			onComplete: function (data) {
				arrWho[0] = data + ' ' + arrWhoText[0]
				if (data > 0) {
					filterDetailItemWhoText.text(arrWho.join(', ')).addClass('filter-detail__text--value')
					filterDetailItemWhoInput.val(arrWho.join(', '))
				} else {
					arrWho[0] = ''
					filterDetailItemWhoInput.val('')
					filterDetailItemWhoText
						.text(filterDetailItemWhoTextDefault)
						.removeClass('filter-detail__text--value')
				}
			},
		})

		APP.qtyVido('.qty-vido--children', {
			qtyMax: 10,
			onComplete: function (data) {
				console.log(arrWhoText)
				arrWho[1] = data + ' ' + arrWhoText[1]
				if (arrWho[0]) {
					filterDetailItemWhoText.text(arrWho.join(', ')).addClass('filter-detail__text--value')
					filterDetailItemWhoInput.val(arrWho.join(', '))
				} else {
					filterDetailItemWhoInput.val('')
				}
			},
		})

		boxRoomItem.each(function () {
			const _this = $(this)
			const input = _this.find('input')
			const inputText = input.data('text')
			arrRoomText.push(inputText)
		})

		APP.qtyVido('.qty-vido--room', {
			qtyMax: 99,
			onComplete: function (data) {
				arrRoom[0] = data + ' ' + arrRoomText[0]
				if (data > 0) {
					filterDetailItemRoomText.text(arrRoom.join(', ')).addClass('filter-detail__text--value')
					filterDetailItemRoomInput.val(arrRoom.join(', '))
				} else {
					arrRoom[0] = ''
					filterDetailItemRoomInput.val('')
					filterDetailItemRoomText
						.text(filterDetailItemRoomTextDefault)
						.removeClass('filter-detail__text--value')
				}
			},
		})

		WINDOW.on('click', function (e) {
			const _this = $(e.target)
			if (
				!_this.closest('.filter-detail__item--checkin, .filter-detail__date, .ui-corner-all')
					.length &&
				!_this.closest('.filter-detail__item--checkout, .filter-detail__date, .ui-corner-all')
					.length
			) {
				filterDetailDateCheckout.removeClass('filter-detail__date--show')
				filterDetailDateCheckin.removeClass('filter-detail__date--show')
			}

			if (!_this.closest('.filter-detail__item--who, .box-qty').length) {
				boxWho.removeClass('box-qty--show')
			}

			if (!_this.closest('.filter-detail__item--room, .box-qty').length) {
				boxRoom.removeClass('box-qty--show')
			}

			if (!_this.closest('.filter-detail__item').length) {
				el.removeClass('filter-detail--show')
				filterDetailItem.removeClass('filter-detail__item--bgwhite')
			}
		})

		filterDetailDateCheckin.datepicker({
			inline: true,
			numberOfMonths: 2,
			minDate: new Date(),
			onSelect: function (selectedDate) {
				filterDetailItemCheckinText
					.text(moment(selectedDate).format('DD/MM/YYYY'))
					.addClass('filter-detail__text--value')
				filterDetailItemCheckinInput.val(selectedDate)
				filterDetailDateCheckout.datepicker('option', 'minDate', new Date(selectedDate))
				filterDetailDateCheckin.removeClass('filter-detail__date--show')
				el.removeClass('filter-detail--show')
				filterDetailItem.removeClass('filter-detail__item--bgwhite')
				const filterDetailItemCheckoutInputValue = filterDetailItemCheckoutInput.val()
				if (filterDetailItemCheckoutInputValue) {
					const newDateCheckout = new Date(filterDetailItemCheckoutInputValue)
					const newDateCheckin = new Date(selectedDate)
					const dateCheckoutTime = newDateCheckout.getTime()
					const dateCheckinTime = newDateCheckin.getTime()
					if (dateCheckoutTime <= dateCheckinTime) {
						filterDetailItemCheckoutText.text(selectedDate)
						filterDetailItemCheckoutInput.val(selectedDate)
					}
				}
			},
		})

		filterDetailDateCheckout.datepicker({
			inline: true,
			numberOfMonths: 2,
			minDate: new Date(),
			onSelect: function (selectedDate) {
				filterDetailItemCheckoutText
					.text(moment(selectedDate).format('DD/MM/YYYY'))
					.addClass('filter-detail__text--value')
				filterDetailItemCheckoutInput.val(selectedDate)
				filterDetailDateCheckout.removeClass('filter-detail__date--show')
				el.removeClass('filter-detail--show')
				filterDetailItem.removeClass('filter-detail__item--bgwhite')
			},
		})

		detectMD()
		APP.resize(detectMD)
	}

	function detectMD() {
		if (WINDOW.width() > 992) {
			filterDetailDateCheckin.datepicker('option', 'numberOfMonths', 2)
			filterDetailDateCheckout.datepicker('option', 'numberOfMonths', 2)
		} else {
			filterDetailDateCheckin.datepicker('option', 'numberOfMonths', 1)
			filterDetailDateCheckout.datepicker('option', 'numberOfMonths', 1)
		}
	}

	init()
}
