APP.hotelFilter = function () {
	const el = $('.box-filter')
	const boxFilterItem = el.find('.box-filter__item')

	const boxFilterItemLocation = el.find('.box-filter__item--location')
	const boxFilterItemLocationInput = boxFilterItemLocation.find('#location')

	const boxFilterItemCheckin = el.find('.box-filter__item--checkin')
	const boxFilterCheckinText = boxFilterItemCheckin.find('.box-filter__text')
	const boxFilterCheckinInput = boxFilterItemCheckin.find('#date-checkin')
	const datepickerCheckin = el.find('.datepicker--checkin')

	const boxFilterItemCheckout = el.find('.box-filter__item--checkout')
	const boxFilterCheckoutText = boxFilterItemCheckout.find('.box-filter__text')
	const boxFilterCheckoutInput = boxFilterItemCheckout.find('#date-checkout')
	const datepickerCheckout = el.find('.datepicker--checkout')

	const boxFilterItemWho = el.find('.box-filter__item--who')
	const boxFilterAdults = boxFilterItemWho.find('#adults')
	const boxFilterChildren = boxFilterItemWho.find('#children')
	const boxFilterItemWhoText = boxFilterItemWho.find('.box-filter__text')
	const boxFilterItemWhoTextDefault = boxFilterItemWhoText.data('text')
	const boxWho = boxFilterItemWho.find('.box-qty')
	const boxWhoItem = boxWho.find('.box-qty__item')

	const arrWhoText = []
	const arrWho = []

	const availableTags = [
		'An Giang',
		'Kon Tum',
		'Đắk Nông',
		'Sóc Trăng',
		'TP. Hà Nội',
		'TP. Hồ Chí Minh',
	]

	function init() {
		if (el.length === 0) return

		boxWhoItem.each(function () {
			const _this = $(this)
			const input = _this.find('input')
			const inputText = input.data('text')
			arrWhoText.push(inputText)
		})

		APP.qtyVido('.qty-vido--adults', {
			qtyMax: 10,
			onComplete: function (data) {
				arrWho[0] = data + ' ' + arrWhoText[0]
				if (data > 0) {
					boxFilterItemWhoText.text(arrWho.join(', ')).addClass('box-filter__text--value')
					boxFilterAdults.val(data)
				} else {
					arrWho[0] = ''
					boxFilterAdults.val('')
					boxFilterItemWhoText
						.text(boxFilterItemWhoTextDefault)
						.removeClass('box-filter__text--value')
				}
			},
		})

		APP.qtyVido('.qty-vido--children', {
			qtyMax: 10,
			onComplete: function (data) {
				arrWho[1] = data + ' ' + arrWhoText[1]
				if (arrWho[0]) {
					boxFilterItemWhoText.text(arrWho.join(', ')).addClass('box-filter__text--value')
					boxFilterChildren.val(data)
				} else {
					boxFilterChildren.val('')
				}
			},
		})

		boxFilterItemLocationInput.autocomplete({
			source: availableTags,
			appendTo: '.box-filter__item--location',
		})

		boxFilterItemLocation.on('click', function () {
			const _this = $(this)
			el.addClass('box-filter--show')
			boxFilterItem.removeClass('box-filter__item--bgwhite')
			_this.addClass('box-filter__item--bgwhite')
		})

		boxFilterItemCheckin.on('click', function () {
			const _this = $(this)

			$('.datepicker--checkin').addClass('datepicker--show')
			$('.datepicker--checkout').removeClass('datepicker--show')

			el.addClass('box-filter--show')
			boxFilterItem.removeClass('box-filter__item--bgwhite')
			_this.addClass('box-filter__item--bgwhite')
		})

		boxFilterItemCheckout.on('click', function () {
			const _this = $(this)

			datepickerCheckout.addClass('datepicker--show')
			datepickerCheckin.removeClass('datepicker--show')

			el.addClass('box-filter--show')
			boxFilterItem.removeClass('box-filter__item--bgwhite')
			_this.addClass('box-filter__item--bgwhite')
		})

		boxFilterItemWho.on('click', function () {
			const _this = $(this)
			el.addClass('box-filter--show')
			boxFilterItem.removeClass('box-filter__item--bgwhite')
			_this.addClass('box-filter__item--bgwhite')

			boxWho.addClass('box-qty--show')
		})

		WINDOW.on('click', function (e) {
			const _this = $(e.target)
			if (
				!_this.closest('.box-filter__item--checkin, .datepicker').length &&
				!_this.closest('.box-filter__item--checkout, .datepicker').length
			) {
				datepickerCheckin.removeClass('datepicker--show')
				datepickerCheckout.removeClass('datepicker--show')
			}

			if (!_this.closest('.box-filter__item--who, .box-qty').length) {
				boxWho.removeClass('box-qty--show')
			}

			if (!_this.closest('.box-filter__item').length) {
				el.removeClass('box-filter--show')
				boxFilterItem.removeClass('box-filter__item--bgwhite')
			}
		})

		datepickerCheckin.datepicker({
			inline: true,
			numberOfMonths: 2,
			minDate: new Date(),
			onSelect: function (selectedDate) {
				boxFilterCheckinText
					.text(moment(selectedDate).format('DD/MM/YYYY'))
					.addClass('box-filter__text--value')
				boxFilterCheckinInput.val(selectedDate)
				datepickerCheckout.datepicker('option', 'minDate', new Date(selectedDate))
				datepickerCheckin.removeClass('datepicker--show')
				el.removeClass('box-filter--show')
				boxFilterItem.removeClass('box-filter__item--bgwhite')
				const dateCheckout = boxFilterCheckoutInput.val()
				if (dateCheckout) {
					const newDateCheckout = new Date(dateCheckout)
					const newDateCheckin = new Date(selectedDate)
					const dateCheckoutTime = newDateCheckout.getTime()
					const dateCheckinTime = newDateCheckin.getTime()
					if (dateCheckoutTime <= dateCheckinTime) {
						checkoutDate.text(selectedDate)
						inputCheckoutDate.val(selectedDate)
					}
				}
			},
		})

		datepickerCheckout.datepicker({
			inline: true,
			numberOfMonths: 2,
			minDate: new Date(),
			onSelect: function (selectedDate) {
				boxFilterCheckoutText
					.text(moment(selectedDate).format('DD/MM/YYYY'))
					.addClass('box-filter__text--value')
				boxFilterCheckoutInput.val(selectedDate)
				datepickerCheckout.removeClass('datepicker--show')
				el.removeClass('box-filter--show')
				boxFilterItem.removeClass('box-filter__item--bgwhite')
			},
		})

		detectMD()
		APP.resize(detectMD)
	}

	function detectMD() {
		if (WINDOW.width() > 992) {
			datepickerCheckin.datepicker('option', 'numberOfMonths', 2)
			datepickerCheckout.datepicker('option', 'numberOfMonths', 2)
		} else {
			datepickerCheckin.datepicker('option', 'numberOfMonths', 1)
			datepickerCheckout.datepicker('option', 'numberOfMonths', 1)
		}
	}

	init()
}
