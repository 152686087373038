APP.tourVido = function () {
	const el = $('.tour-vido')
	const slickTourVido = el.find('.slick--tour-vido')

	function init() {
		if (el.length === 0) return

		slickTourVido.slick({
			centerMode: true,
			centerPadding: '0px',
			slidesToShow: 5,
			slidesToScroll: 1,
			swipe: false,
			touchMove: false,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						swipe: true,
						touchMove: true,
						centerMode: false,
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: true,
						arrows: false,
					},
				},
			],
		})
	}

	init()
}
