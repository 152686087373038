APP.booking = function () {
	function init() {
		$('#credit-card').on('change', function () {
			const _this = $(this)
			const value = _this.val()
			$('.method').hide()
			if (value === 'paypal') {
				$('.method--paypal').show()
			} else if (value === 'gpay') {
				$('.method').hide()
			} else {
				$('.method--credit-card').show()
			}
		})
	}

	init()
}
