APP.bestVido = function () {
	const el = $('.best-vido')
	const slickBestVido = el.find('.slick--best')

	function init() {
		if (el.length === 0) return

		slickBestVido.slick({
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: true,
						arrows: false,
					},
				},
			],
		})
	}

	init()
}
