APP.topVido = function () {
	const el = $('.top-vido')
	const slickTopVido = el.find('.slick--top-vido')

	function init() {
		slickTopVido.slick({
			centerMode: true,
			centerPadding: '250px',
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: true,
						arrows: false,
						centerMode: false,
					},
				},
			],
		})
	}

	init()
}
