APP.destinationVido = function () {
	const el = $('.destination-vido')
	const slickDestinationVido = el.find('.slick--reason')

	function init() {
		if (el.length === 0) return

		WINDOW.on('load resize', function () {
			if (WINDOW.width() < 992) {
				slickDestinationVido.not('.slick-initialized').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
					arrows: false,
				})
			} else if (slickDestinationVido.hasClass('slick-initialized')) {
				slickDestinationVido.slick('unslick')
			}
		})
	}

	init()
}
