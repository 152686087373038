APP.taber = function (element, options) {
	const el = $(element)
	const tabMenu = el.find('> .taber__menu')
	const tabBody = el.find('> .taber__body')
	const tabMobile = el.find('.taber__mobile')
	const dropdown = el.find('.dropdown')

	const tabLink = tabMenu.find('> .taber__link')
	const tabContent = tabBody.find('> .taber__content')
	const tabCurrent = tabMobile.find('.taber__current')

	const dropdownChoose = dropdown.find('.dropdown__choose')
	const dropdownList = dropdown.find('.dropdown__list')
	const dropdownItem = dropdownList.find('.dropdown__item')

	const settings = $.extend(
		{
			isHash: false,
			onComplete: function () {},
		},
		options
	)

	const init = function () {
		detect()
		tabLink.on('click', onClickTabLink)
		dropdownChoose.on('click', onClickDropdownChoose)
		dropdownItem.on('click', handleDropdownItem)
	}

	const detect = function () {
		const hash = location.hash

		if (hash && settings.isHash) {
			setTimeout(function () {
				$('.taber__link[href="' + hash + '"]').trigger('click')
			}, 100)
		} else {
			tabLink.eq(0).addClass(CLASS._active)
			tabContent.eq(0).addClass(CLASS._active)
		}
	}

	const detectCollapse = function () {
		const collapseShow = $('.collapse').find('.is-show')
		const collapseShowBody = collapseShow.find('.collapse__body')
		const collapseShowContent = collapseShow.find('.collapse__content')
		const collapseShowContentHeight = collapseShowContent.outerHeight()
		if (collapseShowBody.length > 0) {
			gsap.to(collapseShowBody, {
				duration: 0.3,
				height: collapseShowContentHeight,
				ease: 'power1.out',
			})
		}
	}

	const onClickTabLink = function (evt) {
		evt.preventDefault()
		const obj = $(this)
		const tabShow = obj.attr('href')
		const text = obj.text()
		const link = location.origin + '/' + tabShow

		tabCurrent.html(text)

		tabLink.removeClass(CLASS._active)
		obj.addClass(CLASS._active)

		tabContent.removeClass(CLASS._active)
		$(tabShow).addClass(CLASS._active)
		detectCollapse()

		if (settings.isHash) {
			location.href = link
		}
	}

	function onClickDropdownChoose() {
		dropdown.toggleClass('dropdown--show')
	}

	function handleDropdownItem() {
		const _this = $(this)
		const value = _this.data('value')
		const text = _this.text()

		if (value) {
			tabCurrent.html(text)
			$(`.taber__link[href='${value}']`).trigger('click')
			dropdown.removeClass('dropdown--show')
		}
	}

	init()
}
