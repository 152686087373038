APP.tourFilter = function () {
	const el = $('.filter-tour')
	const filterTourItem = el.find('.filter-tour__item')

	const filterTourItemLocation = el.find('.filter-tour__item--location')
	const filterTourItemLocationInput = filterTourItemLocation.find('#location')

	const filterTourItemTourType = el.find('.filter-tour__item--tour-type')
	const filterTourItemTourTypeInput = filterTourItemTourType.find('#tour-type')

	const filterTourItemTourOut = el.find('.filter-tour__item--tour-out')
	const filterTourItemTourOutText = filterTourItemTourOut.find('.filter-tour__text')
	const filterTourItemTourOutInput = filterTourItemTourOut.find('#tour-out')
	const filterCarDateTour = el.find('.filter-tour__date--tour')

	const filterTourItemWho = el.find('.filter-tour__item--who')
	const boxFilterAdults = filterTourItemWho.find('#adults')
	const boxFilterChildren = filterTourItemWho.find('#children')
	const filterTourItemWhoText = filterTourItemWho.find('.filter-tour__text')
	const filterTourItemWhoTextDefault = filterTourItemWhoText.data('text')
	const boxWho = filterTourItemWho.find('.box-qty')
	const boxWhoItem = boxWho.find('.box-qty__item')

	const arrWhoText = []
	const arrWho = []

	const availableTags = [
		'An Giang',
		'Kon Tum',
		'Đắk Nông',
		'Sóc Trăng',
		'TP. Hà Nội',
		'TP. Hồ Chí Minh',
	]

	const tourTypes = [
		'An Giang',
		'Kon Tum',
		'Đắk Nông',
		'Sóc Trăng',
		'TP. Hà Nội',
		'TP. Hồ Chí Minh',
	]

	function init() {
		if (el.length === 0) return

		boxWhoItem.each(function () {
			const _this = $(this)
			const input = _this.find('input')
			const inputText = input.data('text')
			arrWhoText.push(inputText)
		})

		APP.qtyVido('.qty-vido--adults', {
			qtyMax: 10,
			onComplete: function (data) {
				arrWho[0] = data + ' ' + arrWhoText[0]
				if (data > 0) {
					filterTourItemWhoText.text(arrWho.join(', ')).addClass('filter-tour__text--value')
					boxFilterAdults.val(data)
				} else {
					arrWho[0] = ''
					boxFilterAdults.val('')
					filterTourItemWhoText
						.text(filterTourItemWhoTextDefault)
						.removeClass('filter-tour__text--value')
				}
			},
		})

		APP.qtyVido('.qty-vido--children', {
			qtyMax: 10,
			onComplete: function (data) {
				arrWho[1] = data + ' ' + arrWhoText[1]
				if (arrWho[0]) {
					filterTourItemWhoText.text(arrWho.join(', ')).addClass('filter-tour__text--value')
					boxFilterChildren.val(data)
				} else {
					boxFilterChildren.val('')
				}
			},
		})

		filterTourItemLocationInput.autocomplete({
			source: availableTags,
			appendTo: '.filter-tour__item--location',
		})

		filterTourItemTourTypeInput.autocomplete({
			source: tourTypes,
			appendTo: '.filter-tour__item--tour-type',
		})

		filterTourItemLocation.on('click', function () {
			const _this = $(this)
			el.addClass('filter-tour--show')
			filterTourItem.removeClass('filter-tour__item--bgwhite')
			_this.addClass('filter-tour__item--bgwhite')
		})

		filterTourItemTourOut.on('click', function () {
			const _this = $(this)

			filterCarDateTour.addClass('filter-tour__date--show')

			el.addClass('filter-tour--show')
			filterTourItem.removeClass('filter-tour__item--bgwhite')
			_this.addClass('filter-tour__item--bgwhite')
		})

		filterTourItemWho.on('click', function () {
			const _this = $(this)
			el.addClass('filter-tour--show')
			filterTourItem.removeClass('filter-tour__item--bgwhite')
			_this.addClass('filter-tour__item--bgwhite')

			boxWho.addClass('box-qty--show')
		})

		WINDOW.on('click', function (e) {
			const _this = $(e.target)
			if (!_this.closest('.filter-tour__item--tour-out, .filter-tour__date--tour').length) {
				filterCarDateTour.removeClass('filter-tour__date--show')
			}

			if (!_this.closest('.filter-tour__item--who, .box-qty').length) {
				boxWho.removeClass('box-qty--show')
			}

			if (!_this.closest('.filter-tour__item').length) {
				el.removeClass('filter-tour--show')
				filterTourItem.removeClass('filter-tour__item--bgwhite')
			}
		})

		filterCarDateTour.datepicker({
			inline: true,
			minDate: new Date(),
			onSelect: function (selectedDate) {
				filterTourItemTourOutText
					.text(moment(selectedDate).format('DD/MM/YYYY'))
					.addClass('filter-tour__text--value')
				filterTourItemTourOutInput.val(selectedDate)
				filterCarDateTour.removeClass('filter-tour__date--show')
				el.removeClass('filter-tour--show')
				filterTourItem.removeClass('filter-tour__item--bgwhite')
			},
		})
	}

	init()
}
