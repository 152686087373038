APP.search = function () {
	const el = $('.search')
	const searchIcon = el.find('.search__icon')
	const btnCancel = el.find('.box-search__cancel')

	const init = () => {
		if (!el.length) return

		searchIcon.on('click', onClickSearch)

		btnCancel.on('click', handleSearchClose)

		WINDOW.on('click', function (e) {
			const _this = $(e.target)
			if (!_this.closest('.search').length) {
				el.removeClass('search--show')
			}
		})
	}

	const onClickSearch = () => {
		el.toggleClass('search--show')
	}

	function handleSearchClose() {
		el.removeClass('search--show')
	}

	init()
}
