APP.init = function () {
	APP.device()
	APP.header()
	APP.search()
	APP.modal()
	APP.user()
	APP.aboutUs()
	APP.coreVido()
	APP.topVido()
	APP.tourVido()
	APP.bestVido()
	APP.destinationVido()
	APP.filterVido()
	APP.hotelDetail()
	APP.filterDetail()
	APP.hotelFilter()
	APP.tourFilter()
	APP.tourDetail()
	APP.booking()
	APP.cart()

	APP.preloader({
		onComplete() {},
	})

	APP.collapse('.collapse', {
		isOpen: true,
	})

	APP.accordion('.accordion')

	$('.taber').each(function () {
		APP.taber(this)
	})

	$('.toggle').on('click', function (e) {
		e.preventDefault()
		APP.sidebar('.sidebar--nav')
	})

	$('.contact-link').on('click', function (e) {
		e.preventDefault()
		APP.sidebar('.sidebar--contact')
	})

	$('[data-show-modal]').on('click', function (e) {
		e.preventDefault()
		const _this = $(this)
		const showModal = _this.data('show-modal')
		if (showModal) APP.modal(showModal)
	})

	$('select').select2({
		minimumResultsForSearch: -1,
	})
}
