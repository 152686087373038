APP.user = function () {
	const el = $('.user')
	const userSignin = el.find('.user__icon--signin')
	const userMenu = el.find('.user__icon--menu')

	function init() {
		if (!el.length) return

		userSignin.on('click', handleUserSignin)
		userMenu.on('click', handleUserMenu)

		WINDOW.on('click', function (e) {
			const _this = $(e.target)
			if (!_this.closest('.user').length) {
				el.removeClass('user--show')
			}
		})
	}

	function handleUserSignin() {
		APP.modal('.modal--signin')
	}

	function handleUserMenu() {
		el.toggleClass('user--show')
	}

	init()
}
