APP.header = function () {
	const el = $('.header')

	const lang = el.find('.lang')
	const langCurrent = lang.find('.lang__current')

	//let lastScrollTop = 0

	const init = () => {
		if (!el.length) return

		detectScroll()

		langCurrent.on('click', onChangeLang)

		WINDOW.on('click', function (e) {
			const _this = $(e.target)
			if (!_this.closest('.lang').length) {
				lang.removeClass('lang--show')
			}
		})
	}

	const onChangeLang = () => {
		lang.toggleClass('lang--show')
	}

	function detectScroll() {
		const header = $('.header')
		const headerH = header.outerHeight(true)
		let lastScrollTop = 0
		let scrollTop = WINDOW.scrollTop()

		if (scrollTop > headerH) {
			header.addClass('header--move')
		} else {
			header.removeClass('header--move')
		}

		WINDOW.on('scroll', function (evt) {
			let scrollTop = $(this).scrollTop()

			if (lastScrollTop <= 0) {
				lastScrollTop = 0
			}

			if (scrollTop > headerH) {
				header.addClass('header--move').addClass('header--small')
			} else {
				header.removeClass('header--move').removeClass('header--small')
			}

			if (scrollTop > lastScrollTop && !BODY.hasClass(CLASS._noscroll)) {
				if (scrollTop > headerH) {
					header.addClass('header--small')
				} else {
					header.removeClass('header--small')
				}
			} else {
				header.removeClass('header--small')
			}

			lastScrollTop = scrollTop
		})
	}

	init()
}
