APP.tourDetail = function () {
	const el = $('.tour-detail')
	const datepickerAvaiable = $('.datepicker-avaiable')
	const datepickerAvaiableDate = $('.datepicker-avaiable__date')
	const btnShowAvaiableDate = $('.tour-available__group')
	const btnHideAvaiableDate = $('.btn--hide-avaiable-date')
	const loader = datepickerAvaiable.find('.loader')

	const tourCollapse = el.find('.tour-collapse')
	const tourCollapseTitle = tourCollapse.find('.tour-collapse__title')

	const profit = $('.profit')

	let tourId = ''

	let soltOutDates = []

	function getAvaiableDate(tourId, month, year) {
		loader.show()
		$.post('/tours/available_date', {
			tourId: tourId,
			month: month,
			year: year,
		})
			.done(function (response) {
				loader.hide()
				soltOutDates = response
				datepickerAvaiableDate.datepicker('refresh')
			})
			.fail(function () {
				loader.hide()
			})
	}

	function init() {
		if (el.length === 0) return

		$('.slick--carousel').slick()

		datepickerAvaiableDate.datepicker({
			inline: true,
			minDate: new Date(),
			onChangeMonthYear: function (year, month) {
				getAvaiableDate(tourId, month, year)
			},
			beforeShowDay: function (date) {
				const stringDate = parseInt($.datepicker.formatDate('dd', date))
				if (soltOutDates.indexOf(stringDate) == -1) {
					return [true, 'ui-sold-out']
				}
				return [true, '']
			},
			onSelect: function (selectedDate) {
				$('.tour-available__text').text(moment(selectedDate).format('DD/MM/YYYY'))
				$('.tour-available__input').val(selectedDate)
				datepickerAvaiable.removeClass('datepicker-avaiable--show')
			},
		})

		btnShowAvaiableDate.on('click', function () {
			const _this = $(this)
			const date = new Date()

			tourId = _this.data('tour-id')

			datepickerAvaiable.toggleClass('datepicker-avaiable--show')
			getAvaiableDate(tourId, date.getMonth() + 1, date.getFullYear())
		})

		btnHideAvaiableDate.on('click', function () {
			datepickerAvaiable.removeClass('datepicker-avaiable--show')
		})

		tourCollapseTitle.on('click', function () {
			const _this = $(this)
			const collapseVidoItemCurrent = _this.parents('.tour-collapse__item')
			const collapseVidoBodyCurrent = collapseVidoItemCurrent.find('.tour-collapse__body')
			const collapseVidoContentCurrent = collapseVidoItemCurrent.find('.tour-collapse__content')
			let collapseVidoContentCurrentHeight = 0

			if (collapseVidoItemCurrent.hasClass(CLASS._show)) {
				collapseVidoBodyCurrent.removeClass(CLASS._show)
				collapseVidoItemCurrent.removeClass(CLASS._show)

				gsap.to(collapseVidoBodyCurrent, {
					duration: 0.3,
					height: 0,
					ease: 'power1.out',
					onComplete: function () {
						collapseVidoBodyCurrent.removeAttr('style')
						gsap.set(collapseVidoBodyCurrent, {
							display: 'none',
						})
					},
				})
			} else {
				collapseVidoBodyCurrent.addClass(CLASS._show)
				collapseVidoItemCurrent.addClass(CLASS._show)

				gsap.set(collapseVidoBodyCurrent, {
					display: 'block',
					height: 0,
				})

				collapseVidoContentCurrentHeight = collapseVidoContentCurrent.outerHeight(true)

				gsap.to(collapseVidoBodyCurrent, {
					duration: 0.6,
					height: collapseVidoContentCurrentHeight,
					ease: 'power1.out',
					onComplete: function () {
						collapseVidoBodyCurrent.removeAttr('style')
						gsap.set(collapseVidoBodyCurrent, {
							display: 'block',
						})
					},
				})
			}
		})

		APP.qtyVido('.qty-vido--adults', {
			qtyMax: $('.qty-vido--adults').find('.qty-vido__btn--plus').data('max'),
			// onComplete: function (data) {
			// 	arrWho[0] = data + ' ' + arrWhoText[0]
			// 	if (data > 0) {
			// 		//filterTourItemWhoText.text(arrWho.join(', ')).addClass('filter-tour__text--value')
			// 		//boxFilterAdults.val(data)
			// 	} else {
			// 		arrWho[0] = ''
			// 		boxFilterAdults.val('')
			// 		filterTourItemWhoText
			// 			.text(filterTourItemWhoTextDefault)
			// 			.removeClass('filter-tour__text--value')
			// 	}
			// },
		})

		APP.qtyVido('.qty-vido--children', {
			qtyMax: $('.qty-vido--children').find('.qty-vido__btn--plus').data('max'),
			// onComplete: function (data) {
			// 	arrWho[1] = data + ' ' + arrWhoText[1]
			// 	if (arrWho[0]) {
			// 		filterTourItemWhoText.text(arrWho.join(', ')).addClass('filter-tour__text--value')
			// 		boxFilterChildren.val(data)
			// 	} else {
			// 		boxFilterChildren.val('')
			// 	}
			// },
		})

		$('.profit__input').mask('#,##0', {
			reverse: true,
			translation: {
				'#': {
					pattern: /-|\d/,
					recursive: true,
				},
			},
			onChange: function (value, e) {
				var target = e.target,
					position = target.selectionStart

				target.value = value
					.replace(/(?!^)-/g, '')
					.replace(/^,/, '')
					.replace(/^-,/, '-')

				target.selectionEnd = position
			},
		})

		$('.profit__change').on('click', function () {
			const _this = $(this)
			const parent = _this.parents('.profit')
			const profitInput = parent.find('.profit__input')
			const profitText = parent.find('.profit__text')
			const profitTextPercent = profitInput.data('text-percent')
			const profitTextPrice = profitInput.data('text-price')
			if (!parent.hasClass('profit--price')) {
				parent.addClass('profit--price')
				profitText.text(profitTextPrice)
			} else {
				parent.removeClass('profit--price')
				profitText.text(profitTextPercent)
			}
			profitInput.val(0)
		})

		$('.checkbox--button input').on('click', function () {
			const _this = $(this)
			const value = _this.val()
			const parent = _this.parent('.checkbox--button')
			const index = parent.index()
			if (_this.is(':checked')) {
				$(`[data-transportation="${value}"]`).eq(index).show()
			} else {
				$(`[data-transportation="${value}"]`).eq(index).hide()
			}
		})

		$('.radio--button input').on('click', function () {
			const _this = $(this)
			const value = _this.val()
			const parent = _this.parent('.radio--button')
			const index = parent.index()

			$(`[data-select-hotel="${value}"]`).hide()

			if (_this.is(':checked')) {
				$(`[data-select-hotel="${value}"]`).eq(index).show()
			}
		})
	}

	init()
}
