APP.aboutUs = function () {
	const slider = $('.slider')
	const slickFor = slider.find('.slick--for')
	const slickNav = slider.find('.slick--nav')
	const sliderThumb = slider.find('.slider__thumb')
	const slickForItem = slickFor.find('.slick__item')
	let slickForItemHeight = slickForItem.outerHeight()

	function init() {
		slickFor.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			draggable: false,
			swipe: false,
			asNavFor: '.slick--nav',
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
						draggable: false,
						swipe: false,
					},
				},
			],
		})

		slickNav.slick({
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 1,
			asNavFor: '.slick--for',
			dots: false,
			arrows: false,
			centerMode: false,
			focusOnSelect: true,
			vertical: true,
			verticalSwiping: true,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						vertical: false,
						verticalSwiping: false,
					},
				},
			],
		})

		detectHeightSlickNav()
		APP.resize(detectHeightSlickNav)

		$('.scrollbar-outer').scrollbar()
	}

	function detectHeightSlickNav() {
		const winW = WINDOW.width()
		if (winW >= 992) {
			slickForItemHeight = slickForItem.outerHeight()
			sliderThumb.height(slickForItemHeight)
		} else {
			sliderThumb.removeAttr('style')
		}
	}

	init()
}
