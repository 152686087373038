APP.sidebar = function (element) {
	const el = $(element)
	const sidebarClose = el.find('.sidebar__close')

	function init() {
		if (!el.length) return
		handleShowSidebar()
		sidebarClose.on('click', handleCloseSidebar)
	}

	function handleShowSidebar() {
		gsap.set(el, {
			display: 'block',
			autoAlpha: 0,
		})

		gsap.to(el, {
			duration: 0.5,
			autoAlpha: 1,
			onComplete: function () {
				el.addClass('sidebar--show')
			},
		})
	}

	function handleCloseSidebar(e) {
		e.preventDefault()

		el.removeClass('sidebar--show')

		gsap.to(el, {
			autoAlpha: 0,
			delay: 0.15,
			onComplete: function () {
				el.removeAttr('style')
			},
		})
	}

	init()
}
