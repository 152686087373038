APP.cart = function () {
	function init() {
		APP.qtyVido('.qty-vido--cart', {
			qtyMax: 10,
			onComplete: function (data) {
				console.log(data)
			},
		})
	}

	init()
}
