APP.coreVido = function () {
	const el = $('.core-vido')
	const slickCoreVido = el.find('.slick--core')

	function init() {
		slickCoreVido.slick({
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						arrows: false,
						dots: true,
					},
				},
			],
		})
	}

	init()
}
