APP.modal = function (element, options) {
	const el = $(element)
	const modalClose = el.find('.modal__close')

	function init() {
		if (!el.length) return

		showModal()

		modalClose.on('click', handleModalClose)
	}

	function showModal() {
		el.addClass('modal--show')

		gsap.set(el, {
			display: 'block',
			autoAlpha: 0,
		})

		gsap.to(el, {
			autoAlpha: 1,
		})
	}

	function handleModalClose() {
		gsap.to(el, {
			autoAlpha: 0,
			onComplete: function () {
				el.removeAttr('style')
				el.removeClass('modal--show')
			},
		})
	}

	init()
}
