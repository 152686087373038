APP.filterVido = function () {
	const el = $('.filter-vido')
	const btnFilter = $('.result-vido__filter')
	const closeFilter = el.find('.close--filter')
	const priceRange = el.find('#price-range')

	function init() {
		if (el.length === 0) return

		btnFilter.on('click', handleToggleFilter)
		closeFilter.on('click', handleToggleFilter)

		priceRange
			.slider({
				min: 0,
				max: 800,
				step: 1,
				value: 0,
				range: true,
				tooltip: 'hide',
				ticks: [0, 800],
				//ticks_labels: ['0 USD', '800+USD'],
			})
			.on('change', function () {
				const value = $(this).val()
				const arrValue = value.split(',')
				$('.price-vido__min').text(arrValue[0])
				$('.price-vido__max').text(arrValue[1])
			})
	}

	function handleToggleFilter() {
		el.toggleClass('filter-vido--show')
	}

	init()
}
