APP.hotelDetail = function () {
	const el = $('.hotel-vido')
	const datepickerAvaiable = $('.datepicker-avaiable')
	const datepickerAvaiableDate = $('.datepicker-avaiable__date')
	const btnShowAvaiableDate = $('.btn--show-avaiable-date')
	const btnHideAvaiableDate = $('.btn--hide-avaiable-date')
	const loader = datepickerAvaiable.find('.loader')

	const collapseVido = el.find('.collapse-vido')
	const collapseVidoTitle = collapseVido.find('.collapse-vido__title')
	let roomId = ''
	let roomType = ''

	let soltOutDates = []

	function getAvaiableDate(roomId, roomType, month, year) {
		loader.show()
		$.post('/hotels/available_date', {
			roomId: roomId,
			type: roomType,
			month: month,
			year: year,
		})
			.done(function (response) {
				loader.hide()
				soltOutDates = response
				datepickerAvaiableDate.datepicker('refresh')
			})
			.fail(function () {
				loader.hide()
			})
	}

	function init() {
		if (el.length === 0) return

		$('.slick--carousel').slick()

		datepickerAvaiableDate.datepicker({
			inline: true,
			minDate: new Date(),
			onChangeMonthYear: function (year, month) {
				getAvaiableDate(roomId, roomType, month, year)
			},
			// beforeShowDay: function (date) {
			// 	const stringDate = parseInt($.datepicker.formatDate('dd', date))
			// 	if (soltOutDates.indexOf(stringDate) == -1) {
			// 		return [true, 'ui-sold-out']
			// 	}
			// 	return [true, '']
			// },
			onSelect: function (selectedDate) {
				console.log(this)
				datepickerAvaiable.removeClass('datepicker-avaiable--show')
				$(this)
					.parents('.card-avaiable__date')
					.find('.btn__text')
					.html(moment(selectedDate).format('DD/MM/YYYY'))
			},
		})

		btnShowAvaiableDate.on('click', function () {
			const _this = $(this)
			const parent = _this.parents('.card-avaiable__date')
			const datepickerAvaiableCurrent = parent.find('.datepicker-avaiable')
			const date = new Date()

			roomId = _this.data('room-id')
			roomType = _this.data('room-type')

			datepickerAvaiableCurrent.toggleClass('datepicker-avaiable--show')
			getAvaiableDate(roomId, roomType, date.getMonth() + 1, date.getFullYear())
		})

		btnHideAvaiableDate.on('click', function () {
			datepickerAvaiable.removeClass('datepicker-avaiable--show')
		})

		collapseVidoTitle.on('click', function () {
			const _this = $(this)
			const collapseVidoItemCurrent = _this.parents('.collapse-vido__item')
			const collapseVidoBodyCurrent = collapseVidoItemCurrent.find('.collapse-vido__body')
			const collapseVidoContentCurrent = collapseVidoItemCurrent.find('.collapse-vido__content')
			const collapseVidoContentCurrentHeight = collapseVidoContentCurrent.outerHeight(true)

			if (collapseVidoItemCurrent.hasClass(CLASS._show)) {
				collapseVidoBodyCurrent.removeClass(CLASS._show)
				collapseVidoItemCurrent.removeClass(CLASS._show)

				gsap.to(collapseVidoBodyCurrent, {
					duration: 0.3,
					height: 0,
					ease: 'power1.out',
				})
			} else {
				collapseVidoBodyCurrent.addClass(CLASS._show)
				collapseVidoItemCurrent.addClass(CLASS._show)

				gsap.to(collapseVidoBodyCurrent, {
					duration: 0.3,
					height: 0,
					ease: 'power1.out',
				})
				gsap.to(collapseVidoBodyCurrent, {
					duration: 0.6,
					height: collapseVidoContentCurrentHeight,
					ease: 'power1.out',
				})
			}
		})
	}

	init()
}
