APP.qtyVido = function (element, options) {
	const el = $(element)
	const btnSub = el.find('.qty-vido__btn--sub')
	const btnPlus = el.find('.qty-vido__btn--plus')
	const input = el.find('.qty-vido__input')
	let inputValue = input.val()

	const settings = $.extend(
		{
			qtyMax: 100,
			qtyMin: 0,
			onComplete: function () {},
		},
		options
	)

	function init() {
		btnSub.on('click', handleBtnSub)
		btnPlus.on('click', handleBtnPlus)

		checkBtnDisabled()
	}

	function handleBtnSub() {
		let value = parseInt(inputValue) - 1
		input.val(value)
		inputValue = value

		checkBtnDisabled()

		settings.onComplete(inputValue)
	}

	function handleBtnPlus() {
		let value = parseInt(inputValue) + 1
		input.val(value)
		inputValue = value

		checkBtnDisabled()

		settings.onComplete(inputValue)
	}

	function checkBtnDisabled() {
		if (inputValue <= settings.qtyMin) {
			btnSub.addClass('qty-vido__btn--disabled')
		} else {
			btnSub.removeClass('qty-vido__btn--disabled')
		}

		if (inputValue >= settings.qtyMax) {
			btnPlus.addClass('qty-vido__btn--disabled')
		} else {
			btnPlus.removeClass('qty-vido__btn--disabled')
		}
	}

	init()
}
